<template>
  <div
    v-if="(configuration.VUE_APP_SSO_LOGIN_URL_WITH_REDIRECT && !user) || !currentFeature"
    class="no-access"
  >
    <h3>
      🔒&nbsp;Vous n'avez pas accès à ce signalement
      <span v-if="!user"> en tant qu'utilisateur anonyme&nbsp;🥸</span>
    </h3>
    <p v-if="!user">
      Veuillez vous connectez afin de pouvoir visualiser le document
    </p>
  </div>

  <div
    v-else
    :class="['preview', { is_pdf }]"
  >  
    <embed
      v-if="is_pdf"
      :src="src"
      type="application/pdf"
    >
    <div v-else>
      <img
        :src="src"
        alt="Aperçu de l'image"
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AttachmentPreview',

  computed: {
    ...mapState([
      'configuration',
      'user'
    ]),
    ...mapState('feature', [
      'currentFeature'
    ]),

    src() {
      return this.$route.query.file;
    },

    is_pdf() {
      return this.src && this.src.includes('pdf');
    },
  },

  watch: {
    user() {
      /**
       * Specific for platform with login by token
       * When the user is setted, fetching again the feature with the cookies setted
       * since automatic authentification can take time to return the response
       * setting the cookies, while the app is loading already
       */ 
      this.getFeature();
    }
  },

  mounted() {
    this.getFeature();
  },
  
  methods: {
    getFeature() {
      console.log('getFeature'); // Keeping for debugging after deployment
      this.$store.dispatch('feature/GET_PROJECT_FEATURE', {
        project_slug: this.$route.params.slug,
        feature_id: this.$route.params.slug_signal,
      });
    }
  }
};
</script>

<style scoped lang="less">
.no-access {
  > h1 {
    margin: 2em 0;
  }
  height: 60vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  > * {
    text-align: center;
  }
}

.preview {
  width: 100vw;
  &.is_pdf {
    padding: 0;
    @media screen and (min-width: 726px) {
      height: calc(100vh - 70px - 1em);
      margin: .5em auto;
      box-shadow: 1px 2px 10px grey;
    }
    @media screen and (max-width: 725px) {
      height: calc(100vh - 110px);
      margin: 0 auto;
    }
  }
  > * {
    height: 100%;
    width: 100%;
  }
  > div {
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
}
</style>